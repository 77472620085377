import { useContext, useState } from 'react'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { t } from '../utils/i18n'

import AdvancedSearch from './AdvancedSearch'
import Avatar from './Avatar'
import Announcements from './Announcements'

import styles from './_header.module.scss'
import { useAuth } from '../utils/auth'
import { songsPath } from '../utils/routeHelpers'
import { BlockScrollContext } from '../pages/_app'
import { ModalsContext } from '../utils/modals'
import { useRouter } from 'next/router'
import classnames from 'classnames'
import { useTheme } from 'next-themes'
import { LogoHeader } from './LogoHeader'
interface HeaderProps {
  hasSearch?: boolean
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { theme } = useTheme()
  const { currentUser } = useAuth()
  const router = useRouter()

  const [isMenuOpen, toggleMenu] = useState(false)
  const { setBlockScroll } = useContext(BlockScrollContext)
  const { openModal, closeModal } = useContext(ModalsContext)
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const openSignInModal = () => {
    openModal({
      name: 'SignIn',
      props: {
        onClose: closeModal
      },
      modalTitle: t('Sign In')
    })
  }

  const logoLink =
    router.route == '/gift_subscription' ? 'https://worshiponline.com/' : '/'

  const logoTheme = router.route == '/artists/[id]' ? 'dark' : theme || 'dark'

  return (
    <header
      role="banner"
      className={`${styles.header} ${props.hasSearch ? styles.hasSearch : ''}`}
    >
      <button
        className={styles.hamburger}
        type="button"
        onClick={() => {
          toggleMenu(!isMenuOpen)
          setBlockScroll(!isMenuOpen)
        }}
      >
        {isMenuOpen ? (
          <FontAwesomeIcon icon={faTimes} />
        ) : (
          <FontAwesomeIcon icon={faBars} />
        )}
      </button>

      <Link href={logoLink} prefetch={false} className={styles.logo}>
        <LogoHeader width={190} height={45} theme={logoTheme} />
      </Link>

      {router.route !== '/welcome' &&
        router.route !== '/signup' &&
        router.route !== '/signup/clickfunnels' &&
        router.route !== '/gift_subscription' && (
          <>
            <div className={isMenuOpen ? styles.menuOpen : styles.menuClosed}>
              <nav
                className={styles.nav}
                role="nav"
                aria-label="main navigation"
              >
                <ul>
                  <li>
                    <Link
                      href={songsPath()}
                      prefetch={false}
                      onClick={() => setBlockScroll(!isMenuOpen)}
                    >
                      {t('songs')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/artists"
                      prefetch={false}
                      onClick={() => setBlockScroll(!isMenuOpen)}
                    >
                      {t('Artists')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/playlists"
                      prefetch={false}
                      onClick={() => setBlockScroll(!isMenuOpen)}
                    >
                      {t('Playlists')}
                    </Link>
                  </li>
                  {currentUser && (
                    <li>
                      <Link
                        href="/setlists"
                        prefetch={false}
                        onClick={() => setBlockScroll(!isMenuOpen)}
                      >
                        {t('Setlists')}
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>

              {props.hasSearch ? (
                <AdvancedSearch redirectToSongsOnEnterPressed={true} />
              ) : null}

              {currentUser ? (
                <Avatar user={currentUser} />
              ) : (
                <div className={styles.signoutOptions}>
                  <Link
                    href="https://worshiponline.com/pricing/"
                    prefetch={false}
                    className="button is-regular is-purple"
                  >
                    {t('Free Trial')}
                  </Link>
                  <button
                    className="button is-regular"
                    onClick={openSignInModal}
                  >
                    {t('Sign In')}
                  </button>
                </div>
              )}
            </div>
            <div className={styles.mobileSearch}>
              <div
                className={styles.searchIcon}
                onClick={() => {
                  setIsSearchOpen(true)
                }}
              >
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
              <div
                className={classnames(styles.searchContainer, {
                  [styles.searchContainerOpen]: isSearchOpen
                })}
              >
                <AdvancedSearch redirectToSongsOnEnterPressed={true} />
                <div
                  className={styles.closeSearch}
                  onClick={() => {
                    setIsSearchOpen(false)
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            </div>
            {currentUser && <Announcements />}
          </>
        )}
    </header>
  )
}

Header.defaultProps = {
  hasSearch: true
} as Partial<HeaderProps>

export default Header
