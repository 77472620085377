import { useMutation } from '@apollo/client'
import { SIGN_OUT_MUTATION } from '../gql/mutations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { t } from '../utils/i18n'
import { useAuth } from '../utils/auth'
import { useRouter } from 'next/router'
import useErrorHandling from '../utils/hooks/useErrorHandling'
import { refreshCurrentUserQueries } from '../utils/auth/CurrentUserProvider'
import { useTheme } from 'next-themes'

const SignOutLink: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { setCurrentUser } = useAuth()
  const { setTheme } = useTheme()
  const router = useRouter()
  const errorHandling = useErrorHandling()

  const [doLogout] = useMutation(SIGN_OUT_MUTATION, {
    onError: errorHandling,
    onCompleted: () => {
      const routerPromise = router.push('/')
      Promise.race([routerPromise]).then(() => setCurrentUser(null))
      setTheme('dark')
    },
    refetchQueries: refreshCurrentUserQueries()
  })

  return (
    <a
      onClick={() => {
        doLogout()
        onClick && onClick()
      }}
    >
      <FontAwesomeIcon icon={faSignOutAlt} />
      <span>{t('Sign Out')}</span>
    </a>
  )
}

export default SignOutLink
