import { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCog,
  faShoppingBag,
  faFlag,
  faComment,
  faPlus,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import styles from './_avatar.module.scss'
import { t } from '../utils/i18n'
import SignOutLink from './SignOutLink'
import classnames from 'classnames'
import { storeUrl } from '../utils/routeHelpers'
import Link from 'next/link'
import { BlockScrollContext } from '../pages/_app'
import Image from 'next/legacy/image'
import { useTheme } from 'next-themes'
import lightThemeIcon from '@public/images/light_theme.svg'
import darkThemeIcon from '@public/images/dark_theme.svg'
import Switcher from '../components/ui/SwitcherComponent'
import { UPDATE_USER_SETTINGS_MUTATION } from '../gql'
import { useMutation } from '@apollo/client'
import useErrorHandling from '../utils/hooks/useErrorHandling'
import { AppearanceEnum } from '../__generated__/graphql'
interface DropdownProps {
  isMusician: boolean
  isDropdownOpen: boolean
  setIsDropdownOpen: (isDropdownOpen: boolean) => void
  onShow: () => void
  canceledAt: Date | null
}

const AvatarDropdown: React.FC<DropdownProps> = ({
  isMusician,
  isDropdownOpen,
  setIsDropdownOpen,
  onShow
}) => {
  const { setBlockScroll } = useContext(BlockScrollContext)

  const clickHandler = () => {
    setIsDropdownOpen(false)
    setBlockScroll(false)
  }
  const { theme, setTheme } = useTheme()

  const errorHandling = useErrorHandling()

  const [updateUser] = useMutation(UPDATE_USER_SETTINGS_MUTATION, {
    onError: errorHandling,
    onCompleted: (data) => {
      setTheme(data.updateUserSettings.user.appearance || 'dark')
    }
  })

  const updateTheme = (theme: AppearanceEnum) => {
    updateUser({ variables: { input: { appearance: theme } } })
  }

  return (
    <div className={styles.dropdown}>
      <div className={styles.dropdownContainer}>
        <button
          type="button"
          className={styles.dropdownCloseButton}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <ul>
          <li>
            <Link href="/account" onClick={clickHandler}>
              <FontAwesomeIcon icon={faCog} />
              <span>{t('Account')}</span>
            </Link>
          </li>
          <li>
            <a
              href={storeUrl()}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                setIsDropdownOpen(false)
                setBlockScroll(false)
              }}
            >
              <FontAwesomeIcon icon={faShoppingBag} />
              <span>{t('Store')}</span>
            </a>
          </li>
          <li className={classnames({ [styles.displayNone]: isMusician })}>
            <Link href="/team" onClick={clickHandler}>
              <FontAwesomeIcon icon={faFlag} />
              <span>{t('Team')}</span>
            </Link>
          </li>
          <li>
            <a
              onClick={() => {
                onShow()
                clickHandler()
              }}
            >
              <FontAwesomeIcon icon={faComment} />
              <span>{t('Chat With Us')}</span>
            </a>
          </li>
          <li>
            <Link
              href="/song_requests"
              onClick={() => setIsDropdownOpen(false)}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span>{t('Request a Song')}</span>
            </Link>
          </li>
          <li>
            <SignOutLink onClick={clickHandler} />
          </li>
        </ul>
      </div>
      {theme == 'light' ? (
        <div className={styles.darkTheme}>
          <Image alt="dark mode" src={darkThemeIcon} />
          <Switcher
            label={t('Dark Mode')}
            onClick={() => {
              updateTheme(AppearanceEnum.Dark)
            }}
            className={styles.switcherDark}
            isChecked={false}
          />
        </div>
      ) : (
        <div className={styles.lightTheme}>
          <Image alt="light mode" src={lightThemeIcon} />
          <Switcher
            label={t('Light Mode')}
            onClick={() => {
              updateTheme(AppearanceEnum.Light)
            }}
            isChecked={false}
            className={styles.switcherLight}
          />
        </div>
      )}
    </div>
  )
}
export default AvatarDropdown
