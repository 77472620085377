import { useEffect, useRef } from 'react'
import styles from './_switcher.module.scss'
import classnames from 'classnames'

interface SwitcherProps {
  label?: string
  rightLabel?: string
  className?: string
  name?: string
  isChecked: boolean
  onClick: () => void
}

const SwitcherComponent: React.FC<SwitcherProps> = ({
  label,
  rightLabel,
  className,
  name,
  onClick,
  isChecked
}) => {
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    inputRef.current.checked = isChecked
  }, [isChecked])

  return (
    <div
      className={classnames('switcher', styles.switcher, {
        [className]: className
      })}
    >
      <div className={styles.switcherContainer}>
        <input
          ref={inputRef}
          type="checkbox"
          name={name}
          defaultChecked={isChecked}
        />
        <span onClick={onClick} className={styles.label}>
          {label}
        </span>
        {rightLabel && <span>{rightLabel}</span>}
      </div>
    </div>
  )
}

SwitcherComponent.defaultProps = {
  name: 'switch'
}

export default SwitcherComponent
