import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'
import { t } from '../utils/i18n'

import styles from './_advancedSearch.module.scss'
import { useLazyQuery } from '@apollo/client'

import {
  albumPath,
  artistPath,
  songPath,
  songsPath
} from '../utils/routeHelpers'
import { useRouter } from 'next/router'
import Image from 'next/legacy/image'
import { SEARCH_QUERY } from '../gql'
import { Song, Artist, Album, Theme, Genre } from '../__generated__/graphql'

class SearchResult {
  songs: [Pick<Song, 'id' | 'title' | 'album' | 'artists' | 'themes'>]
  artists: [Pick<Artist, 'id' | 'name' | 'picture'>]
  albums: [Album]
  themes: [Pick<Theme, 'id' | 'name'>]
  genres: [Pick<Genre, 'id' | 'name'>]

  constructor(data) {
    this.songs = data.songs || []
    this.artists = data.artists || []
    this.albums = data.albums || []
    this.themes = data.themes || []
    this.genres = data.genres || []
  }

  isAny(filters) {
    return filters.reduce((sum, f) => sum + this[f].length, 0) > 0
  }
}

export enum AdvancedSearchTheme {
  homePage = 'homePage',
  common = 'common'
}

interface AdvancedSearchProps {
  isStandalone?: boolean
  filters?: string[]
  onSelect?: (type: string, entity: any) => void
  customClassName?: string
  placeholder?: string
  theme?: AdvancedSearchTheme
  redirectToSongsOnEnterPressed?: boolean
}

const AdvancedSearch: React.FC<AdvancedSearchProps> = ({
  isStandalone,
  onSelect,
  filters = ['songs', 'artists', 'albums', 'themes', 'genres'],
  customClassName,
  placeholder,
  theme,
  redirectToSongsOnEnterPressed = false
}) => {
  const { register, getValues, setValue } = useForm<FormData>({
    mode: 'onChange'
  })

  const [searchResult, setSearchResult] = useState(new SearchResult({}))
  const [query, setQuery] = useState('')
  const router = useRouter()

  const [performSearch] = useLazyQuery(SEARCH_QUERY, {
    onCompleted: (data) => {
      setSearchResult(new SearchResult(data.search))
    }
  })

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
    performSearch({
      variables: {
        q: event.target.value
      }
    })
  }

  const redirectToSongs = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (redirectToSongsOnEnterPressed && event.keyCode === 13) {
      // on "enter" key pressed
      router.push(songsPath({ q: getValues('search') }))
      setValue('search', '')
      setQuery('')
      performSearch({
        variables: {
          q: ''
        }
      })
    }
  }

  const onPick = (type: string, entity: any): boolean => {
    if (onSelect) {
      onSelect(type, entity)
      setQuery('')
      return true
    } else {
      return false
    }
  }

  return (
    <form
      className={classnames(
        styles.advancedSearch,
        customClassName,
        { [styles.isStandalone]: isStandalone, [styles[theme]]: theme },
        'control has-icons-left'
      )}
      onSubmit={(e) => e.preventDefault()} // to not handle "enter" pressed
    >
      <input
        name="search"
        className={`input ${isStandalone && 'is-large'}`}
        type="text"
        onChange={handleSearch}
        ref={register}
        placeholder={placeholder}
        onKeyUp={redirectToSongs}
      />
      <span className="icon is-small is-left">
        <FontAwesomeIcon icon={faSearch} />
      </span>
      <input type="submit" className="is-hidden" />
      {searchResult.isAny(filters) && query.length > 0 && (
        <div className={styles.searchDropdown}>
          {filters.includes('songs') && searchResult.songs.length > 0 && (
            <section className={styles.songs}>
              <header>
                <h2>{t('Songs')}</h2>
                {theme === AdvancedSearchTheme.homePage && (
                  <a
                    className={styles.viewAll}
                    href={songsPath()}
                    onClick={(e) => e.preventDefault()}
                  >
                    {t('View All')}
                  </a>
                )}
              </header>
              <ul
                className={classnames(
                  `columns is-flex-wrap  is-variable ${styles.songs}`,
                  {
                    'is-1': theme === AdvancedSearchTheme.homePage,
                    'is-5': theme === AdvancedSearchTheme.common
                  }
                )}
              >
                {' '}
                {searchResult.songs.map((song) => (
                  <li
                    className={classnames('column', {
                      'is-half': theme === AdvancedSearchTheme.homePage,
                      'is-one-fifth': theme === AdvancedSearchTheme.common
                    })}
                    key={song.id}
                  >
                    <a
                      className={styles.searchThing}
                      href={songPath(song)}
                      onClick={(e) => {
                        if (onPick('song', song)) {
                          e.preventDefault()
                        }
                      }}
                    >
                      <div className={styles.searchImage}>
                        <Image
                          src={song.album.cover.medium}
                          alt={`${song.title} ${song.album.name}`}
                          width={207}
                          height={207}
                        />
                      </div>
                      <h3>
                        <strong>{song.title}</strong>
                        {song.album.name}
                      </h3>
                    </a>
                  </li>
                ))}
              </ul>
            </section>
          )}

          {filters.includes('artists') && searchResult.artists.length > 0 && (
            <section className={styles.artists}>
              <header>
                <h2>{t('Artists')}</h2>
                <a className={styles.viewAll} href="/artists">
                  {t('View All')}
                </a>
              </header>
              <ul className="columns is-flex-wrap">
                {searchResult.artists.map((artist: Artist) => (
                  <li className="column is-half" key={artist.id}>
                    <a
                      className={styles.searchThing}
                      href={artistPath(artist)}
                      onClick={(e) => {
                        if (onPick('artist', artist)) {
                          e.preventDefault()
                        }
                      }}
                    >
                      <div className={styles.imgContainer}>
                        <Image
                          src={artist.picture.medium}
                          alt={artist.name}
                          width={46}
                          height={46}
                          layout="fixed"
                        />
                      </div>
                      <h3>{artist.name}</h3>
                    </a>
                  </li>
                ))}
              </ul>
            </section>
          )}

          {filters.includes('albums') && searchResult.albums.length > 0 && (
            <section className={styles.albums}>
              <header>
                <h2>{t('Albums')}</h2>
              </header>
              <ul className="columns">
                {searchResult.albums.map((album) => (
                  <li className="column is-one-third" key={album.id}>
                    <a
                      className={styles.searchThing}
                      href={albumPath(album)}
                      onClick={(e) => {
                        if (onPick('album', album)) {
                          e.preventDefault()
                        }
                      }}
                    >
                      <Image
                        src={album.cover.medium}
                        alt={album.name}
                        width={120}
                        height={120}
                      />
                      <h3>
                        <strong>{album.name}</strong>
                        {album.name}
                      </h3>
                    </a>
                  </li>
                ))}
              </ul>
            </section>
          )}

          {filters.includes('themes') && searchResult.themes.length > 0 && (
            <section className={styles.themes}>
              <header>
                <h2>{t('themes')}</h2>
                <a className={styles.viewAll} href="/playlists#themes">
                  {t('View All')}
                </a>
              </header>
              <ul className="columns is-variable is-2">
                {searchResult.themes.map((theme) => (
                  <li className="column is-one-third" key={theme.id}>
                    <a
                      className="button is-white"
                      type="button"
                      href={songsPath({ themeIds: [theme.id] })}
                      onClick={(e) => {
                        if (onPick('theme', theme)) {
                          e.preventDefault()
                        }
                      }}
                    >
                      {theme.name}
                    </a>
                  </li>
                ))}
              </ul>
            </section>
          )}

          {filters.includes('genres') && searchResult.genres.length > 0 && (
            <section className={styles.genres}>
              <header>
                <h2>{t('genres')}</h2>
                <a className={styles.viewAll} href="/playlists#genres">
                  {t('View All')}
                </a>
              </header>
              <ul className="columns is-variable is-2">
                {searchResult.genres.map((genre) => (
                  <li className="column is-one-third" key={genre.id}>
                    <a
                      className="button is-white"
                      type="button"
                      href={songsPath({ genreIds: [genre.id] })}
                      onClick={(e) => {
                        if (onPick('genre', genre)) {
                          e.preventDefault()
                        }
                      }}
                    >
                      {genre.name}
                    </a>
                  </li>
                ))}
              </ul>
            </section>
          )}
        </div>
      )}
    </form>
  )
}

AdvancedSearch.defaultProps = {
  isStandalone: false,
  theme: AdvancedSearchTheme.homePage,
  placeholder: t('Songs, Artists, Albums, Genres, Themes')
}

export default AdvancedSearch
