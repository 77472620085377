import { useState, useRef } from 'react'
import styles from './_avatar.module.scss'
import { useOutsideClick } from '../utils/hooks/useOutsideClick'
import { useAuth } from '../utils/auth'
import { useChat } from 'react-live-chat-loader'
import Image from 'next/legacy/image'
import { User } from '../__generated__/graphql'
import AvatarDropdown from './AvatarDropdown'

interface AvatarProps {
  user: Partial<User>
}

const Avatar: React.FC<AvatarProps> = (props) => {
  const initials = `${props.user.firstName.charAt(
    0
  )} ${props.user.lastName.charAt(0)}`
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { isMusician } = useAuth()
  const [, loadChat] = useChat()
  const ref = useRef()

  useOutsideClick(ref, isDropdownOpen, () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false)
    }
  })

  return (
    <div ref={ref} className={styles.avatarContainer}>
      <button
        className={styles.avatar}
        type="button"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div className={styles.initials}>{initials}</div>
        <Image
          alt={`${props.user.firstName} ${props.user.lastName}`}
          src={props.user.picture}
          width={46}
          height={46}
        />
      </button>
      {isDropdownOpen && (
        <AvatarDropdown
          canceledAt={props.user.canceledAt}
          isMusician={isMusician}
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
          onShow={() => loadChat({ open: true })}
        />
      )}
    </div>
  )
}
export default Avatar
