import { gql } from '../../__generated__/gql'

export const UPDATE_USER_SETTINGS_MUTATION = gql(`
  mutation updateUserSettings($input: UpdateUserSettingsMutationInput!) {
    updateUserSettings(input: $input) {
      user {
        ...userProfileFields
      }
    }
  }
`)
