import { gql } from '../../__generated__/gql'
export const SEARCH_QUERY = gql(`
  query search($q: String!) {
    search(q: $q) {
      artists {
        id
        name
        picture {
          medium
        }
      }
      songs {
        id
        title
        slug
        album {
          id
          cover {
            medium
          }
        }
        artists {
          name
        }
      }
      albums {
        id
        name
        cover {
          medium
        }
        primaryArtist {
          id
        }
      }
      themes {
        id
        name
      }
      genres {
        id
        name
      }
    }
  }
`)
