import React, { useEffect, useState } from 'react'

export interface LogoHeaderProps {
  width: string | number
  height: string | number
  theme: string
}
export const LogoHeader: React.FC<LogoHeaderProps> = (
  props: LogoHeaderProps
) => {
  const [textColor, setTextColor] = useState(null)

  useEffect(() => {
    setTextColor(props.theme === 'light' ? '#000' : '#fff')
  }, [props.theme])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 295.299 66.068"
    >
      <defs>
        <linearGradient
          id="2DERaw"
          x1="0.103"
          y1="1.006"
          x2="1.092"
          y2="-0.156"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#a03c95" stopOpacity="0" />
          <stop offset="1" stopColor="#49c7f2" />
        </linearGradient>
        <linearGradient
          id="GMz8hs"
          x1="-0.053"
          y1="1.193"
          x2="0.931"
          y2="0.031"
          xlinkHref="#2DERaw"
        />
        <linearGradient
          id="9ZjmPy"
          x1="-0.208"
          y1="1.417"
          x2="1.399"
          y2="-0.588"
          xlinkHref="#2DERaw"
        />
      </defs>
      <g transform="translate(-903.897 -807.225)">
        <g transform="translate(903.897 807.225)">
          <path
            d="M953.512,807.46c9.864,3.983,16.417,11.1,16.014,22.6a21.4,21.4,0,0,1-2.232,8.572c-4.758,9.484-9.882,18.785-14.813,28.184a11.474,11.474,0,0,1-8.607,6.442c-5.459.963-11.629-3.7-12.057-9.248a12.911,12.911,0,0,1,1.5-7.34q8.663-16.133,17.184-32.343a34.736,34.736,0,0,0,3.937-11.057A9.4,9.4,0,0,0,953.512,807.46Z"
            transform="translate(-915.243 -807.321)"
            fill="#b700ba"
          />
          <path
            d="M1006.039,807.351c6.234,2.749,11.39,6.236,14.124,12.444a21.312,21.312,0,0,1-.444,19.156c-4.9,9.52-10.026,18.928-15.009,28.407a10.859,10.859,0,0,1-10.578,5.926,10.639,10.639,0,0,1-9.63-8.493,10.749,10.749,0,0,1,1.043-7.534q8.5-15.962,16.968-31.937a38.259,38.259,0,0,0,4.4-11.683A9.716,9.716,0,0,0,1006.039,807.351Z"
            transform="translate(-936.6 -807.276)"
            fill="#b700ba"
          />
          <path
            d="M910.886,807.225c5.19,2.438,9.828,5.043,12.725,9.841,4.6,7.616,4.734,15.365.336,23.072-2.3,4.035-6.076,5.7-10.619,5.249a10.492,10.492,0,0,1-9.156-8.274,11.128,11.128,0,0,1,1.08-7.827c2.327-4.285,4.806-8.51,6.056-13.287C912.06,813.125,912.549,810.254,910.886,807.225Z"
            transform="translate(-903.896 -807.225)"
            fill="#b700ba"
          />
        </g>
        <text
          transform="translate(1002.196 843.073)"
          fill={textColor}
          fontSize="33"
          fontFamily="Avenir-Medium, Avenir, AvenirNext"
          fontWeight="500"
        >
          <tspan x="0" y="0" letterSpacing="0.23em">
            WORSHI
          </tspan>
          <tspan y="0">P</tspan>
        </text>
        <text
          transform="translate(1098.316 865.3)"
          fill={textColor}
          fontSize="14"
          fontFamily="Avenir-Book, Avenir, AvenirNext"
        >
          <tspan x="-87.479" y="0" letterSpacing="1.75em">
            ONLIN
          </tspan>
          <tspan y="0">E</tspan>
        </text>
        <g transform="translate(903.897 807.225)">
          <path
            d="M953.512,807.46c9.864,3.983,16.417,11.1,16.014,22.6a21.4,21.4,0,0,1-2.232,8.572c-4.758,9.484-9.882,18.785-14.813,28.184a11.474,11.474,0,0,1-8.607,6.442c-5.459.963-11.629-3.7-12.057-9.248a12.911,12.911,0,0,1,1.5-7.34q8.663-16.133,17.184-32.343a34.736,34.736,0,0,0,3.937-11.057A9.4,9.4,0,0,0,953.512,807.46Z"
            transform="translate(-915.243 -807.321)"
            fill="url(#2DERaw)"
          />
          <path
            d="M1006.039,807.351c6.234,2.749,11.39,6.236,14.124,12.444a21.312,21.312,0,0,1-.444,19.156c-4.9,9.52-10.026,18.928-15.009,28.407a10.859,10.859,0,0,1-10.578,5.926,10.639,10.639,0,0,1-9.63-8.493,10.749,10.749,0,0,1,1.043-7.534q8.5-15.962,16.968-31.937a38.259,38.259,0,0,0,4.4-11.683A9.716,9.716,0,0,0,1006.039,807.351Z"
            transform="translate(-936.6 -807.276)"
            fill="url(#GMz8hs)"
          />
          <path
            d="M910.886,807.225c5.19,2.438,9.828,5.043,12.725,9.841,4.6,7.616,4.734,15.365.336,23.072-2.3,4.035-6.076,5.7-10.619,5.249a10.492,10.492,0,0,1-9.156-8.274,11.128,11.128,0,0,1,1.08-7.827c2.327-4.285,4.806-8.51,6.056-13.287C912.06,813.125,912.549,810.254,910.886,807.225Z"
            transform="translate(-903.896 -807.225)"
            fill="url(#9ZjmPy)"
          />
        </g>
      </g>
    </svg>
  )
}
